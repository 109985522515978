import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getGameUrl} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import {generalUiTexts} from 'data/ui-texts/general-ui-texts';
import Logo from 'components/ui/logo/logo';
import LoginFacilitatorController from './login-facilitator/login-facilitator-controller';
import CreateUserController from './create-user/create-user-controller';
import LoginPlayerController from './login-player/login-player-controller';
import ResetPassword from './reset-password/reset-password';
import CookieConsent from 'components/ui/cookie-consent/cookie-consent';
import './login.scss';
import { scenariosData } from 'data/scenarios-data';

const Login = ({languageId, playerLoginType, gameData}) => {
	/* Get active box component for facilitator box */
	let [facilitatorComponentType, setFacilitatorComponentType] = useState('login');
	
	/* Get game url */
	const gameUrl = getGameUrl(window.location.pathname);

	/* Get login component */
	let LoginComponent = null; 

	if (gameUrl === appConfig.faclitatorSectionUri) {
		/* Facilitator login */
		LoginComponent = LoginFacilitatorController;
		if (facilitatorComponentType === 'createUser') LoginComponent = CreateUserController;
		if (facilitatorComponentType === 'resetPassword') LoginComponent = ResetPassword;
	} else {
		if (gameData) {
			
			LoginComponent = LoginPlayerController;
		}
	}

	/* Get login title (depends on scenario) */
	let loginTitle = null;
	if (gameData) {
		const scenarioData = scenariosData.find((scenario) => {return scenario.id === gameData.scenarioId;});
		if (scenarioData && scenarioData.loginTitle) {
			loginTitle = getText(scenarioData.loginTitle, languageId);
		}
	}

	/* Check if game is disabled, get text if so */
	let disabledText = null;
	if (gameData && gameData.isDisabled === true) {
		disabledText = getText(generalUiTexts.gameIsDisabled, languageId);
	}

	return (
		<div className="Login">
			{/* Logo */}
			<div className="Login-logoAndTitle">
				<div className="Login-logo"><Logo /></div>
				<div className="Login-title"><span>{loginTitle}</span></div>
			</div>
			
			
			
			{/* Login */}
			{(!disabledText && LoginComponent) && <LoginComponent 
				languageId={languageId} 
				playerLoginType={playerLoginType}
				setFacilitatorComponentType={setFacilitatorComponentType} 
			/>}

			{/* Game is disabeld text */}
			{disabledText && <div className="Login-disabledText">
				<span>{disabledText}</span>	
			</div>}
		
			{/* Cookies */}
			<CookieConsent languageId={languageId} page="login" />

			{/* CGL logo */}
			<div className="Login-logoCGL"><Logo type="cgl"/></div>			
		</div>
	);
};

Login.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerLoginType: PropTypes.string,
	gameData: PropTypes.object
};

export default Login;