const modulesData = [
	{
		id: 's3-m1',
		background: 'production-2',
		title: {
			da: 'Modul 1',
			en: 'Module 1',
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's3-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Liner Game',
			en: 'Liner Game',
		},
		moduleIds: [
			's3-m1',
		]
	},
];

export {
	modulesData,
	moduleGroups
};