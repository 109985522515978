const moduleImages = [	
	'modules/tasks/multiple-choice/s1-m1-hands/background.svg',	
	'modules/tasks/multiple-choice/s2-m1-hair-and-beard/option-1.svg',
	'modules/tasks/multiple-choice/s2-m1-hair-and-beard/option-2.svg',
	'modules/tasks/multiple-choice/s2-m1-hair-and-beard/option-3.svg',
	'modules/tasks/multiple-choice/s2-m1-hair-and-beard/option-4.svg',
	'modules/tasks/multiple-choice/s2-m1-hair-and-beard/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-5.svg',
	'modules/tasks/multiple-choice/s1-m2-visible-cuts-cover/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-visible-cuts-cover/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-visible-cuts-cover/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-visible-cuts-cover/option-4.svg',
	'modules/tasks/spot-errors/s2-m1-production-issues/errors.svg'
	
];

export {
	moduleImages
};