// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const generalUiTexts = {
	start: {
		da: 'Start',
		en: 'Start'
	},
	hygieneTraining: {
		da: 'Hygiejnetræning',
		en: 'Hygiene training'
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
	},
	gameIsDisabled: {
		da: 'Der er lukket for adgang til spillet i øjeblikket.',
		en: 'The game is not open for access at the moment.',
	}
};


module.exports = {
	generalUiTexts
};