import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import resetAnimation from 'helpers/animation-helper';
import './speech-bubble.scss';

const SpeechBubble = ({
	shouldFadeSpeech = false, 
	hasLongFadeInDelay = false, 
	hasFadeInDelay = false, 
	type, 
	taskId, 
	children
}) => {
	useEffect(() => {
		resetAnimation('speech');
	}, [taskId]);

	return (
		<div className={
			'SpeechBubble' + (type ? ' ' + type : '') + 
			(shouldFadeSpeech ? ' fade' : '') + 
			(hasFadeInDelay ? ' hasFadeInDelay' : '') +
			(hasLongFadeInDelay ? ' hasLongFadeInDelay' : '')
		}>
			{children}
		</div>
	);
};

SpeechBubble.propTypes = {
	shouldFadeSpeech: PropTypes.bool,
	hasLongFadeInDelay: PropTypes.bool,
	hasFadeInDelay: PropTypes.bool,
	type: PropTypes.string,
	taskId: PropTypes.string,
	children: PropTypes.any
};

export default SpeechBubble;