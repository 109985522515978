import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyChtFOZTxJBmUsgQlZrSB2XTkUlMx6EeIs',
		authDomain: 'cgl-beck-test.firebaseapp.com',
		projectId: 'cgl-beck-test',
		storageBucket: 'cgl-beck-test.appspot.com',
		messagingSenderId: '109290439451',
		appId: '1:109290439451:web:33ac6c38668981687f89be'	
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyBmvj3oQdvZQ5v30CimTPmsAIqpDKDAPig',
		authDomain: 'cgl-beck-production.firebaseapp.com',
		projectId: 'cgl-beck-production',
		storageBucket: 'cgl-beck-production.appspot.com',
		messagingSenderId: '894404644461',
		appId: '1:894404644461:web:2df43e37a8a24fa2173964'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;