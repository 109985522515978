import PropTypes from 'prop-types';
import './switch.scss';

const Switch = ({isEnabled, onClick}) => {
	
	/**
	 * Handle onClick
	 * @param {object} event 
	 */
	const handleOnClick = (event) => {
		if (onClick) {
			onClick(event);
		}
	};

	return (
		<div 
			className={'Switch' + (isEnabled ? ' enabled' : '')}
			onClick={(event) => {handleOnClick(event);}}
		/>
	);
};



Switch.propTypes = {
	isEnabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired
};

export default Switch;