const modulesData = [
	{
		id: 's2-m1',
		background: 'dressing-room',
		title: {
			da: 'Modul 1',
			en: 'Module 1',
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's2-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Hurtig Hygiejnetræning',
			en: '',
		},
		moduleIds: [
			's2-m1',
		]
	},
];

export {
	modulesData,
	moduleGroups
};