const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		animateCharacter: true,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Velkommen til første modul af hygiejnetræningen. Du skal igennem to korte moduler. Jeg er din leder og hjælper dig gennem spillet.`,
			en: `Welcome to the first module of the hygiene training. You will have to complete two short modules. I'm your manager, and I'll help you through the game.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Du skal have tre stjerner for at gennemføre et modul.`,
			en: `You need to get three stars to advance to the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-start-outside-production',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Vi starter med alt det udenfor produktionen, og så går vi indenfor. Første opgave handler om den rigtige påklædning.`,
			en: `We begin with things outside of the production facilities, and then we'll go inside. The first task is about how to dress properly.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hair-and-beard',
		layout: 's1-m1-hair-and-beard',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde? Tryk på de rigtige billeder.`,
			en: `What is the correct way to use hair- and beardnets? Click on the correct images.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // hair and ears covered by hair net, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // hair and ears covered by hair net, beard covered by beardnet
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis dit skæg er under 10mm, behøver du ikke have skægbind på.`,
								en: `If your beard is less than 3/8 inches, you don't need a beardnet.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // hair sticks out, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // hair and ears covered by hair net, beard but no beardnet
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // ears stick out, no beard
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-technical-staff',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvis du er teknisk personale, så bruger du kasket i stedet for hårnet, og det er helt ok.`,
			en: `If you are technical staff, you'll use a cap instead of a hairnet, and that is okay.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-technical-staff-2',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvis du er teknisk personale og ikke skifter tøj når du er udenfor Beck's matrikel OG skal arbejde med produkter eller over produkter, så skal du også bære eksempelvis en BECK-kittel, en sweatshirt eller en t-shirt.`,
			en: `If you are technical staff and do not change clothes during your time outside the BECK premises AND need to work with products or above products, you must also wear, for example, a BECK smock, a sweatshirt, or a t-shirt.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Hvilket smykke er det eneste, du må have på i produktionen?`,
			en: `What kind of jewelry is the only one allowed in production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // smartwatch
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Bemærk dog at smartwatch ikke er tilladt på vores amerikanske fabrik.`,
								en: `Note that smartwatches are not allowed in our American location.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Næsepiercing, glat, fast lukke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Vielsesring uden sten
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Glat ørering med fast lukke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Vielsesring med sten
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Ørering, stift
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-personal-items',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Så er du korrekt påklædt. Husk du må ikke tage personlige ejendele som medicin, mad og tasker med ind i produktionen. Hvis du har brug for at medbringe for eksempel medicin, så tal med din leder.`,
			en: `Now you are dressed properly. Remember, you are not allowed to bring personal belongings such as medicine, food, and bags into production. If you need to bring medicine, for instance, talk to your manager.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-locker-types',
		background: 'dressing-room',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder.`,
			en: `In the changing facilities there's a locker for your private clothes and a place for your work clothes. It's important to put things in the right places.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-dressing-room',
		background: 'dressing-room',
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede.`,
			en: `Good hygiene begins in the changing facilities. See if you can spot the issues on the next image.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room-locker',
		layout: 's1-m1-dressing-room-locker',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in',
		text: {
			da: `Tryk på de 4 ting som er forkerte på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 things that are wrong on the image. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hearing-protection'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'work-shoes'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'screwdriver'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-work-clothes-in-private',
		text: {
			da: `Hvordan er det med arbejdstøj, må du gerne bruge det i privaten?`,
			en: `What are the rules for work clothes? Are you allowed to use them for personal use?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Nej, aldrig`,
					en: `No, never.`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er ikke tilladt at anvende arbejdstøj uden for Beck matrikel eller lager.`,
								en: `Using work clothes outside of Beck is not allowed.`
							}
						})
					})	
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Ja`, 
					en: `Yes`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Kun på helligdage`,
					en: `Only on holidays`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Kun til korte ærinder`,
					en: `Only for short errands`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-work-clothes-on-visits',
		text: {
			da: `Hvis du er teknisk personale må du gerne lave besøg i andre virksomheder i dit arbejdstøj i forhold til arbejdsrelaterede opgaver. Og lagerpersonale må gerne tage til vores eksterne lager. Men kun hvis du transporterer dig i en af firmaets værkstedsvogne eller en gaffeltruck. Ellers må du skifte tøj.`,
			en: `If you are technical staff, you are allowed to visit other companies in your work clothes for work-related tasks, and warehouse personnel are allowed to go to our external warehouse. But only if you transport yourself in one of the company's service vehicles or a forklift. Otherwise, you must change clothes.`
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-survey-intro',
		text: {
			da: `Inden vi går videre, er der to spørgsmål om fødevaresikkerhedskultur. Her er der ikke rigtige eller forkerte svar, og besvarelserne er anonyme.`,
			en: `Before moving on, we have two questions about food safety culture. In these questions there are no right or wrong answers, and you answer them anonymously.`
		},
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-top-management-priorities',
		shuffleOptions: false,
		title: {
			da: `Topledelsens prioritering af fødevaresikkerhed og kvalitet`,
			en: `Top management priorities`
		},
		text: {
			da: `Hvor enig er du, i at topledelsen hos Beck prioriterer fødevaresikkerhed og kvalitet?`,
			en: `How strongly do you agree with the following statement:
				<br />At Beck the top management prioritises food safety and quality?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-your-priorities',
		shuffleOptions: false,
		title: {
			da: `Eget engagement`,
			en: `Own engagement`
		},
		text: {
			da: `Hvor enig er du i, at du selv er aktiv og engageret i at opretholde hygiejnestandarder og renlighed på arbejdspladsen?`,
			en: `How strongly do you agree with the following statement:
				<br />I am active and engaged in maintaining hygiene standards and cleanliness at the workplace?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-ready-production',
		text: {
			da: `Nu gør vi klar til at gå ind i produktionen.`,
			en: `Now let's get ready to enter the production facilities.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-always-do-before-production',
		text: {
			da: `Hvad skal du altid huske at gøre inden du går ind i produktionen?`,
			en: `What must you always remember to do before entering production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Vaske hænder`,
					en: `Wash my hands`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Give alle en high five`, 
					en: `High five everyone`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Tage hårnet af`,
					en: `Remove my hairnet`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-washing-hands-areas',
		layout: 's1-m1-hands',
		subtype: 'images',
		shuffleOptions: false,
		text: {
			da: `Der er især to områder som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Some areas can be especially easy to forget when washing your hands. Which two are they?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // inside of wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // between fingers
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		text: {
			da: `Håndhygiejne handler også om neglene. 
				<br />Tryk på det billede, som er korrekt.`,
			en: `The nails are also an important part of hand hygiene. 
				<br />Click on the correct image.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // short, clean
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // short, nail polish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // short, slightly dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // long, slightly dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // artificial nails
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-nails-gloves',
		text: {
			da: `Hvis du har kunstige negle eller neglelak på, så skal du bære engangshandsker. Og sikkerhedsbriller hvis du bruger kunstige øjenvipper.`,
			en: `If you are wearing artificial nails or nail polish, you have to wear single use gloves. And safety goggles if you are using artificial eyelashes.`
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-finish',
		text: {
			da: `Så er du igennem første modul. Hvis du har fået tre stjerner kan du med det samme fortsætte til modul 2. Hvis ikke må du tage modulet en gang til.`,
			en: `That's it, you've finished the first module. If you have three stars, you can continue to module 2. If not, you have to complete this module once more.`
		},
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};