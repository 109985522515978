const backgroundsData = [
	{
		id: 'outside',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			}
		],
	},
	{
		id: 'outside-2',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			}
		],
	},
	{
		id: 'dressing-room',
		modifiers: [
			{
				id: 'lockers',
			},
			{
				id: 'zoom-in'
			},
		]
	},
	{
		id: 'production',
		modifiers: []
	},
	{
		id: 'production-2',
		modifiers: []
	}
];

export {backgroundsData};