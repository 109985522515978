const modulesData = [
	{
		id: 's1-m1',
		background: 'dressing-room',
		title: {
			da: 'Modul 1',
			en: 'Module 1',
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
	{
		id: 's1-m2',
		background: 'production',
		title: {
			da: 'Modul 2',
			en: 'Module 2',
		},
		minStars: 3,
		requiredModuleIds: ['s1-m1'],
		tasks: require('./modules/module-2').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's1-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Grundlæggende hygiejnetræning',
			en: '',
		},
		moduleIds: [
			's1-m1',
			's1-m2'
		]
	},
];

export {
	modulesData,
	moduleGroups
};