import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as BeckBackground} from 'assets/images/backgrounds/outside/outside.svg';
import {ReactComponent as BeckBackground2} from 'assets/images/backgrounds/outside-2/outside-2.svg';
import BirdGenerator from '../bird-generator/bird-generator';
import {ReactComponent as Lockers} from 'assets/images/backgrounds/dressing-room/lockers.svg';
import './background.scss';

const Background = ({
	scenarioId = 'scenario-1', 
	bgLanguageId,
	backgroundId = 'outside',
	backgroundModifier,
	backgroundStatus,
	updateBackgroundStatus,
}) => {

	/* Transition ref */
	const transitionTimeout = useRef(null);

	/* Current background and position */
	const [currentBackgroundId, setCurrentBackgroundId] = useState(backgroundId);
	const [currentBackgroundModifier, setcurrentBackgroundModifier] = useState(backgroundModifier);

	/* Background component */
	const BackgroundComponent = (currentBackgroundId === 'outside' 
		? BeckBackground
		: currentBackgroundId === 'outside-2' ? BeckBackground2 : null 
	);

	/**
	 * Update background
	 */
	useEffect(() => {
		if (backgroundId !== currentBackgroundId) {
			if (transitionTimeout.current) clearTimeout(transitionTimeout.current);
			/* New background id: fade between backgrounds */
			transitionTimeout.current = setTimeout(() => {
				setCurrentBackgroundId(backgroundId);
				setcurrentBackgroundModifier(backgroundModifier);
				updateBackgroundStatus('show');
			}, 1000);
		} else {
			if (backgroundModifier !== currentBackgroundModifier) {
				/* New background position: pan to position */
				setcurrentBackgroundModifier(backgroundModifier);
				let timeoutDuration = 1000;
				if (currentBackgroundModifier === 'no-lamps' || backgroundModifier === 'no-lamps') {
					timeoutDuration = 0;
				}
				if (
					currentBackgroundModifier === 'top' && 
					backgroundModifier === 'bottom' &&
					scenarioId === 'scenario-1'
				) {
					timeoutDuration = 4000;
				}
				if (
					['lockers', 'hygiene-lock', 'lockers-hygiene-lock'].includes(currentBackgroundModifier) &&
					['lockers', 'hygiene-lock', 'lockers-hygiene-lock'].includes(backgroundModifier)
				) {
					timeoutDuration = 2000;
				}
				if (transitionTimeout.current) clearTimeout(transitionTimeout.current);
				transitionTimeout.current = setTimeout(() => {
					updateBackgroundStatus('show');
				}, timeoutDuration);
			}
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backgroundId, backgroundModifier]);

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */		
		
		return () => {
			/* Component will unmount */
				
			if (transitionTimeout.current) clearTimeout(transitionTimeout.current);
			
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'Background ' + backgroundId}>
			{/* Background svg component */}
			{BackgroundComponent &&
				<BackgroundComponent 
					className={
						'Background-svgComponent ' + scenarioId + ' ' + currentBackgroundModifier + ' ' +
						(backgroundStatus === 'fade' ? 'transitionOut' : 'transitionIn')
					}
				/>
			}

			{/* Birds */}
			{(backgroundId === 'outside' || backgroundId === 'outside-2') && <BirdGenerator />}

			{/* Background css image */}
			<div 
				className={'Background-imageWrap ' + backgroundStatus + ' '
					+ ' ' + currentBackgroundId + ' ' + currentBackgroundModifier
				}
			>
				<div className={'Background-image1'}/>
				<div className={'Background-lockers ' + bgLanguageId}>
					<Lockers />
				</div>
				<div className={'Background-image2'}/>
			</div>
			
			{/* Fade in/out overlay */}
			<div key={currentBackgroundId} 
				className={'Background-fadeOverlay ' + (backgroundStatus === 'fade' ? 'transitionOut' : 'transitionIn')
				}
			/>
		</div>
	);
};

Background.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	bgLanguageId: PropTypes.string.isRequired,
	backgroundId: PropTypes.string.isRequired,
	backgroundModifier: PropTypes.string,
	backgroundStatus: PropTypes.string.isRequired,
	updateBackgroundStatus: PropTypes.func.isRequired,	
};

export default Background;