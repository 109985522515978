import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {
	getPlayerModuleData,
	checkIfModuleIsDisabled,
	checkIfPlayerHasRequiredNumberOfStars, 
	getPlayerModuleSessionData, 
	getSessionErrorStats
} from 'helpers/module-helper';
import {getModuleMaxPoints, getNumberOfFilledStars} from 'helpers/points-helper';
import {getTimeSpentInSessionFormatted} from 'helpers/time-helper';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import './results.scss';

const Results = (props) => {
	/* Props */
	const {
		isStartingNewSession, 
		languageId, 
		moduleData, 
		scenarioData,
		userData, 
		handleGoToGamePage, 
		handleStartNewSession
	} = props;

	/* Check if module is locked */
	const playerModuleData = getPlayerModuleData(moduleData.id, userData);
	const isDisabled = checkIfModuleIsDisabled(playerModuleData);

	/* "Talk to facilitator" popup */
	const [showTalkToFacilitatorPopup, setShowTalkToFacilitatorPopup] = useState(isDisabled);
	const toggleTalkToFacilitatorPopup = () => {setShowTalkToFacilitatorPopup(!showTalkToFacilitatorPopup);};

	/* Show "try again" button */
	const showTryAgainButton = (isDisabled 
		? false
		: (checkIfPlayerHasRequiredNumberOfStars(userData, moduleData.id) ? false : true)
	);
	
	/* Show "try again" popup */
	const [showTryAgainPopup, setShowTryAgainPopup] = useState(false);
	const toggleTryAgainPopup = () => {setShowTryAgainPopup(!showTryAgainPopup);};

	/* Get player data for module session */
	const playerModuleSession = getPlayerModuleSessionData(userData, moduleData.id);

	/* Get points and stars */
	const moduleMaxPoints = getModuleMaxPoints(moduleData.id);
	const playerPoints = (playerModuleSession && playerModuleSession.points ? playerModuleSession.points : 0);
	const filledStars = getNumberOfFilledStars(playerPoints, moduleMaxPoints);
	const timeSpent = getTimeSpentInSessionFormatted(playerModuleSession);;
	
	/* Get error stats */
	const {numberOfTasks, avrNumberOfErrorsPerTask, numberOfErrorFreeTasks} = 
		getSessionErrorStats(moduleData, playerModuleSession);
	

	return (
		<div className="Results">
			

			<div className='Results-content'>
				{/* Title */}
				<div className="Results-title">
					<span>
						{getText(moduleData.title, languageId)} {getText(playerUiTexts.results, languageId)}:
					</span>
				</div>


				{/* Stars */}
				{scenarioData.pointsAndStarsAreEnabled && <div className="Results-result">
					<div className="Results-resultLabel">
						<span>{getText(playerUiTexts.stars, languageId)}:</span>
					</div>
					<div className="Results-resultValue"><span>{filledStars}</span></div>
				</div>}

				{/* Points */}
				{scenarioData.pointsAndStarsAreEnabled && <div className="Results-result">
					<div className="Results-resultLabel">
						<span>{getText(playerUiTexts.points, languageId)}:</span>
					</div>
					<div className="Results-resultValue"><span>{playerPoints} / {moduleMaxPoints}</span></div>
				</div>}

				{/* Time spent */}
				<div className="Results-result">
					<div className="Results-resultLabel">
						<span>{getText(playerUiTexts.timeSpent, languageId)}:</span>
					</div>
					<div className="Results-resultValue"><span>{timeSpent}</span></div>
				</div>
			
				{/* Avr # of errors */}
				<div className="Results-result">
					<div className="Results-resultLabel">
						<span>{getText(playerUiTexts.avgErrors, languageId)}:</span>
					</div>
					<div className="Results-resultValue"><span>{avrNumberOfErrorsPerTask}</span></div>
				</div>

				{/* No-error tasks */}
				<div className="Results-result">
					<div className="Results-resultLabel">
						<span>{getText(playerUiTexts.noErrorsTasks, languageId)}:</span>
					</div>
					<div className="Results-resultValue"><span>{numberOfErrorFreeTasks}/{numberOfTasks}</span></div>
				</div>

				{/* Buttons */}
				<div className="Results-buttons">
					<div className="Results-backBtn">
						<Button 
							classes={['blueDark']} 
							text={getText(playerUiTexts.backToModuleOverview, languageId)} 
							onClick={() => {handleGoToGamePage('modules-overview');}} 
						/>
					</div>
					{(
						showTryAgainButton || 
						(!isDisabled && appConfig.env !== 'demo' && appConfig.env !== 'production')
					) && 
						<div className="Results-retryBtn">
							<Button 
								classes={['blueDark']} 
								text={getText(playerUiTexts.tryAgain, languageId)} 
								onClick={() => {toggleTryAgainPopup();}} 
							/>
						</div>}
				</div>

				{/* "Try again" popup */}
				{showTryAgainPopup && 
					<Popup 
						type="try-again"
						title={getText(playerUiTexts.confirmTryAgainPopup.title, languageId)}
						text={getText(playerUiTexts.confirmTryAgainPopup.text, languageId)}
						buttons={[
							{
								isLoading: isStartingNewSession,
								classes: ['green', 'try-again'],
								text: getText(playerUiTexts.confirmTryAgainPopup.resetBtn, languageId),
								action: handleStartNewSession
							},
							{
								classes: ['red', 'try-again'],
								text: getText(playerUiTexts.confirmTryAgainPopup.cancelBtn, languageId),
								action: toggleTryAgainPopup
							}
						]}
						togglePopup={toggleTryAgainPopup}
					/>
				}

				{/* "Talk to facilitator popup" */}
				{showTalkToFacilitatorPopup &&
					<Popup	
						type="talk-to-facilitator"
						text={getText(playerUiTexts.talkToFacilitatorPopup.text, languageId)}
						buttons={[
							{
								classes: ['yellow'],
								text: getText(playerUiTexts.talkToFacilitatorPopup.okBtn, languageId),
								action: toggleTalkToFacilitatorPopup
							}
						]}
						togglePopup={toggleTalkToFacilitatorPopup}
					/>
				}
			</div>
		</div>
	);
};

Results.propTypes = {
	isStartingNewSession: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	moduleData: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	scenarioData: PropTypes.object.isRequired,
	handleGoToGamePage: PropTypes.func.isRequired,
	handleStartNewSession: PropTypes.func.isRequired,
};

export default Results;
