import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {scenariosData} from 'data/scenarios-data';
import ModulesOverview from './modules-overview/modules-overview';
import ModuleController from './module/module-controller';
import './game.scss';

const Game = (props) => {
	const {
		languageId, 
		backgroundStatus,
		userData, 
		gameData, 
		scrollToTop, 
		handleGoToPage, 
		updateUser, 
		resetPlayerGameData, 
		saveSurveyResponse,
		handleLogout,
		setBackground,
		handleShakeScreen
	} = props;

	const scenarioId = (gameData.scenarioId ? gameData.scenarioId : 'scenario-1');
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
	
	const initialPageId = (scenarioData.modulesData.length === 1 ? 'module' : 'modules-overview');
	const initialModuleId = (scenarioData.modulesData.length === 1 ? scenarioData.modulesData[0].id : null);

	/* Game page */
	const [navigation, setNavigation] = useState({
		pageId: initialPageId, 
		moduleId: initialModuleId
	});

	/**
	 * Go to game page
	 * @param {string} pageId
	 * @param {string} moduleId
	 */
	const handleGoToGamePage = (pageId, moduleId = null) => {
		if (pageId === 'modules-overview') {
			setBackground(languageId, scenarioData.background, 'bottom');
		}
		setNavigation({pageId, moduleId});
	};

	/* Game page component */
	let GamePageComponent = ModulesOverview;
	if (navigation.pageId === 'module' && navigation.moduleId) GamePageComponent = ModuleController;

	return (
		<div className={'Game ' + navigation.pageId}>
			{backgroundStatus !== 'fade' && <GamePageComponent 
				languageId={languageId}
				backgroundStatus={backgroundStatus}
				moduleId={navigation.moduleId}
				userData={userData}
				gameData={gameData}
				scrollToTop={scrollToTop}
				handleGoToGamePage={handleGoToGamePage}
				handleGoToPage={handleGoToPage}
				updateUser={updateUser}
				resetPlayerGameData={resetPlayerGameData}
				saveSurveyResponse={saveSurveyResponse}
				handleLogout={handleLogout}
				setBackground={setBackground}
				handleShakeScreen={handleShakeScreen}
			/>}
		</div>
	);
};

Game.propTypes = {
	languageId: PropTypes.string.isRequired,
	backgroundStatus: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	resetPlayerGameData: PropTypes.func.isRequired,
	saveSurveyResponse: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	setBackground: PropTypes.func.isRequired,
	handleShakeScreen: PropTypes.func.isRequired,
};

export default Game;
