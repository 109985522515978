const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome',
		background: 'dressing-room',
		animateCharacter: true,
		text: {
			da: `Velkommen til andet modul af hygiejnetræningen. Her handler det om at sikre hygiejnen i produktionen.`,
			en: `Welcome to the second module of the hygiene training. Now it's about how to maintain hygiene standards in production.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-stars-to-complete',
		background: 'dressing-room',
		text: {
			da: `Husk du skal have tre stjerner for at gennemføre.`,
			en: `Remember, you need three stars to complete the training.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-important-rule',
		background: 'dressing-room',
		text: {
			da: `Inden du går ind i produktionen, er der en vigtig regel.`,
			en: `Before entering production, we have an important rule.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-suspect-disease',
		background: 'dressing-room',
		text: {
			da: `Hvis du har mistanke om en fødevarebåren sygdom - det kan være influenza eller madforgiftning - så kontakt nærmeste arbejdsleder. Men det her er et spil, så du må gerne fortsætte.`,
			en: `If you suspect you've been infected with a foodborne illness - for instance stomach flu or food poisoning - contact your closest manager. But since this is a game, you are allowed to continue.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-always-wear-production',
		subtype: 'images',
		background: 'dressing-room',
		layout: 's1-m2-always-wear-production',
		text: {
			da: `Hvad skal du altid have på i produktionen?`,
			en: `What must you always wear in production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Sorte sikkerhedssko
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Redningsvest
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Sydvest 
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-visible-cuts-cover',
		subtype: 'images',
		background: 'dressing-room',
		layout: 's1-m2-visible-cuts-cover',
		text: {
			da: `Hvis nu du har synlige sår eller rifter, hvad skal du så sætte på?`,
			en: `If you have visible wounds or scratches, what should you put on it?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Blåt plaster
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Vores blå plaster er detekterbart, så du må kun bruge udleverede plastre.`,
								en: `Our blue band aids are detectable. So you are only allowed to use our band aids.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Almindeligt plaster
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Kæmpe bandage 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Plastikpose hen over armen. 
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-wash-or-disinfect-hands',
		background: 'dressing-room',
		showRequiredNumberOfAnswers: false,
		shuffleOptions: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske eller afspritte hænder?
				<br />Vælg 1 - og husk at læse alle mulighederne:`,
			en: `After which situations must you remember to wash or sanitise your hands?
				<br />Choose 1 - and remember to read all the options.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Efter pauser`,
					en: `After breaks`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ikke forkert, men der er et mere rigtigt svar.`,
								en: `Not wrong, but there is a more correct answer.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter at binde snørebånd`, 
					en: `After tying your shoelaces`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ikke forkert, men der er et mere rigtigt svar.`,
								en: `Not wrong, but there is a more correct answer.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Når du har rørt ved din mund og næse`,
					en: `After touching your mouth or nose`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ikke forkert, men der er et mere rigtigt svar.`,
								en: `Not wrong, but there is a more correct answer.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Efter brug af stiger`,
					en: `After using ladders`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ikke forkert, men der er et mere rigtigt svar.`,
								en: `Not wrong, but there is a more correct answer.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all of the situations above`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Husk, at hvis du har spist, så skal du <i>vaske</i> hænderne.`,
								en: `Remember, if you have eaten you must wash your hands.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-hygiene-extra-attention',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why must we pay extra attention to hygiene in production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `Because of the law`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because bad hygiene can make people sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the reasons above`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-production-hygiene-issues',
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at production. On the next image, spot the things that are problematic when it comes to hygiene.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-hygiene-issues',
		layout: 's1-m2-production-hygiene-issues',
		backgroundModifier: 'no-lamps',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 5 issues. Click OK when you are ready or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'wooden-handle',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig! Der må ikke indgå træ i værktøj eller rengøringsudstyr.`,
								en: `Exactly! Only use tools and equipment without wood.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'window'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'piece-of-glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knife'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'lamp'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-condensation-water',
		text: {
			da: `Hvis der drypper fx kondensvand ned fra befugtningsanlægget, så skal øverste ark fjernes. Hvorfor?`,
			en: `If condensation is dripping on the products from the humidifier, you have to remove the top sheet. Why?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan forurene produkterne`,
					en: `It can contaminate the products`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Produkterne må gerne blive på båndet`, 
					en: `The products are allowed to stay on the belt`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan skabe oversvømmelser`,
					en: `It can cause a flooding`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kondens tiltrækker skadedyr`,
					en: `Condense attracts pests`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-close-to-production',
		text: {
			da: `Du er tæt på produktionen hver dag, og derfor lægger du garanteret mærke til ting, som andre overser. Og hvis du ser et problem, så husk at reagere på det.`,
			en: `You are close to the production every day, so you will most likely notice things others overlook. And if you see something, remember to act on it.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-survey-intro',
		text: {
			da: `Hos Beck laver vi løbende en masse registreringer i forhold til hygiejne og fødevaresikkerhed. Det kommer der et par spørgsmål om.`,
			en: `At Beck we continuously make registrations about hygiene and food safety. The next couple of questions are about that.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-no-wrong-answers',
		text: {
			da: `På de to næste spørgsmål er der ikke rigtige og forkerte svar. Og husk, at besvarelserne er anonyme.`,
			en: `The following two questions don't have right or wrong answers. And remember that you answer anonymously.`
		},
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-colleagues-remember',
		shuffleOptions: false,
		title: {
			da: `Kollegaer registrerer data`,
			en: `Colleagues register data`
		},
		text: {
			da: `Hvor enig er du i, at dine kollegaer husker at registrere alle nødvendige data, som krævet i Share Point/<i>We do Beck?</i>`,
			en: `How strongly do you agree with the following statement: 
				<br />My colleagues remember to register all the data required by Share Point/<i>We Do Beck?</i>`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-registrations-improvements',
		shuffleOptions: false,
		title: {
			da: `Registeringer og afvigelser`,
			en: `Registrations and deviations`
		},
		text: {
			da: `Hvor enig er du i, at registreringer og afvigelser bliver brugt til at lave forbedringer?`,
			en: `How strongly do you agree with the following statement: 
				<br />Registrations and deviations are used to make improvements?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-contamination',
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der.`,
			en: `Now, let's look at contamination. Contamination is basically when things get into the products that aren't supposed to be there.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-broken-lamp',
		text: {
			da: `En lampe går i stykker i produktionen, og splinter falder af. Der er risiko for, der er kommet fremmedlegmer i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp gets broken in production. There's a risk of foreign bodies in the products. What should you do? Place the actions below in the right order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Stop produktionen`,
					en: `Stop production`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Hent rengøringsvogn`,
					en: `Get cleaning cart`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Følg vejledning`,
					en: `Follow the manual`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Tilkald leder/kvalitet`,
					en: `Summon manager/quality`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-allergies',
		subtype: 'images',
		layout: 's1-m2-allergies',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Klik på det som ALDRIG må medbringes på Beck.`,
			en: `Contamination can be a problem for people with allergies. Click on the thing you may NEVER bring to Beck.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Nuts
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Flour
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // strawberry 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // apple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // pineapple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Milk
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-good-hygiene-starts-with-you',
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and thus high quality begins with you. See if you can spot the issues on the next image. `
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-issues',
		layout: 's1-m2-production-issues',
		background: 'production',
		text: {
			da: `Tryk på de 3 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 3 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'oil',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knife'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-vermin',
		text: {
			da: `Et af de helt store problemer i forhold til hygiejne er, hvis der er skadedyr.`,
			en: `One of the very big problems when it comes to hygiene, is if we get pests inside the production area.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-mouse',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: `You spot a mouse on the floor in production. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on the mouse while I call for help`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`, 
					en: `Try to catch the mouse and then call for help if I don't succeed`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell my closest manager at the end of work`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-dos-and-donts',
		text: {
			da: `Sidste opgave handler om, hvad du må og ikke må gøre.`,
			en: `The final task is about do's and don'ts.`
		}
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 's1-m2-sort-allowed-or-not',
		background: 'production',
		text: {
			da: `Sortér følgende i hvad der er tilladt, hvad der ikke tilladt og hvad der er tilladt i særlige områder hos Beck.`,
			en: `Sort the following into what is allowed, what is not allowed, and what is allowed in marked areas on Beck.`
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1,
				title: {
					da: `Tilladt`,
					en: `Allowed`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2,
				title: {
					da: `Ikke tilladt`,
					en: `Not allowed`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3,
				title: {
					da: `Tilladt i særlige områder`,
					en: `Allowed in marked areas`
				}
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, text: {da: `Rygning og snus`, en: `Smoking and snuff`}, categoryIds: [3]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, text: {da: `Drikke vand`, en: `Drinking water`}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, text: {da: `Drikke andet end vand`, en: `Drinking other things besides water`}, categoryIds: [3]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, text: {da: `Nøddeprodukter`, en: `Products containing nuts`}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, text: {da: `Hoste og nyse ved produkterne`, en: `Coughing and sneezing near product`}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, text: {da: `Mobiltelefon`, en: `Cellphones`}, categoryIds: [3],
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Husk dog at den skal have påmonteret panserglas. Husk også du skal have en tilladelse fra ansvarlig leder.`,
								en: `Please remember that it must have a cover with tempered glass. And you will also need a permit from a manager.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, text: {da: `Tyggegummi`, en: `Chewing gum`}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 8, text: {da: `Spisning`, en: `Eating`}, categoryIds: [3],
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Al spisning skal foregå i kantinen - også frugt og lignende.`,
								en: `You are only allowed to eat in the cafeteria - that includes fruit and the like.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-survey-intro2',
		text: {
			da: `Du er næsten færdig. Vi har lige et sidste spørgsmål om fødevaresikkerhedskultur. Husk der ikke er rigtige og forkerte svar, og at besvarelserne er anonyme.`,
			en: `You are almost done. We just have a final question about food safety culture. Remember, there are no right and wrong answers, and you answer anonymously.`
		},
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-ready-to-find-and-report-issues',
		shuffleOptions: false,
		title: {
			da: `Klar til at identificere og rapportere problemer`,
			en: `Prepared to identify and report issues`
		},
		text: {
			da: `Hvor enig er du i at du er blevet "klædt på" til at identificere og rapportere problemer med fødevaresikkerhed?`,
			en: `How strongly do you agree with the following statement: 
				<br />I feel that I've been properly prepared to identify and report issues with food safety?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-finish',
		text: {
			da: `Sådan, du er nu igennem hygiejnetræningen. Hvis du har fået tre stjerner, er du færdig for denne gang. Hvis ikke, så må du tage modulet en gang til.`,
			en: `That's it, you've completed the hygiene training. If you have three stars, you are done for now. If not, you'll have to do the module once more.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};