const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-welcome',
		animateCharacter: true,
		background: 'outside-2',
		backgroundModifier: 'bottom',
		characters: ['manager head-1 armLeft-1 armRight-2'],
		text: {
			en: `Welcome to the Beck Liner Game. Hi. I´m here to find out what you’ve learnt from your Beck liner Training and to give you some helpful information along the way.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-certify',
		background: 'outside-2',
		backgroundModifier: 'bottom',
		characters: ['manager head-1 armLeft-1 armRight-2'],
		text: {
			en: `This game will help you understand our products and make better fish blocks`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-stars-to-pass',
		background: 'outside-2',
		backgroundModifier: 'bottom',
		characters: ['manager head-1 armLeft-1 armRight-2'],
		text: {
			en: `Solving tasks earn you points and stars. You need three stars to complete the game. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-perfect-fish-block',
		text: {
			en: `Let's start with the basics. What is true of the perfect fish block?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					en: `Has sharp edges and corners`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					en: `Stays stuck in liner`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `No! Ideally we want it to release easily from the liner.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					en: `No air or ice pockets`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					en: `Weighs 8-10 kg`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Nope. We want it to weigh in at around 7.5 kg or 16.5 lbs.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					en: `Has visible freezer burns`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-perfect-fish-block',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `You get the perfect fish block by using a Beck liner and following our recommendations.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-too-much-water',
		text: {
			en: `We want the fish to be fresh, but it mustn't contain too much water. Why is too much water a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Drip loss affects block weight`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Yes! We want the block to weigh the same on delivery as when we pack it.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Can cause ice pockets`, 
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Gets too heavy`,
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Can cause decay`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `The fish will be frozen, so that is not an issue.`
							}
						})
					})
				],
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-water-check',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		images: ['s3-m1-block-too-much-water'],
		text: {
			en: `To check if there is too much water, push the fillets to one side. If you can see water like in the image, the fish should be drained.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-perfect-fish-block2',
		layout: 's3-m1-fish-blocks',
		subtype: 'images',
		text: {
			en: `Which fish block is perfect?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // perfect fish block
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2 // fish block with visible ice
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // fish block dripping water
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // fish block with jagged corner
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-liners-sop',
		text: {
			en: `When using liners in production, what is the standard operating procedure?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					en: `First in first out (FIFO)`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					en: `Don't pre-stack liners`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					en: `Pre-stack liners`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					en: `Insert liner in frame just before filling it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					en: `Insert liner in frame well in advance of filling it`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-pre-stack-problem',
		image: 'prestacked-liners',
		text: {
			en: `What is the problem with pre-stacking liners in frames like this?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					en: `It will damage the liners`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					en: `Increases risk of contamination`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Yes. When pre-stacking, you will handle and touch the liners a bit more, and that increases the risk.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					en: `It's a safety hazard`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Unless you stack them really high, safety shouldn't be an issue.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					en: `It can damage the frames`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-correct-frame',
		layout: 's3-m1-frames',
		subtype: 'images',
		text: {
			en: `You want the entire frame to touch the block - this will make for more effective freezing. Click on the frame that is correct.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // frame that fits a fish block
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Yes! And the recommended dimensions are: 485x255x59mm.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2 // frame that is too high
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // frame that is too low
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // frame with dents and cuts
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-frame-bottom',
		layout: 's3-m1-frame-bottoms',
		subtype: 'images',
		text: {
			en: `Check the bottom of the frame too. Click on the ones that you should NOT use.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // Frame with a hole that "pops" invard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // Frame with frayed hole
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // Perfect frame
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-frame-bottom-holes',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `Uneven holes at the bottom of a frame can ruin the liner. This can lead to freezer burns and contamination.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's3-m1-order-insert-liner-in-frame',
		layout: 's3-m1-insert-liner-in-frame',
		subtype: 'images',
		text: {
			en: `How should you insert the liner in a frame? Place the images below in the right order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-perfect-liner-in-frame',
		layout: 's3-m1-liner-in-frames',
		subtype: 'images',
		text: {
			en: `Which one of these is correct?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Perfect fit, no round corners
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Yes! A Beck-liner fits naturally into the frame. But make sure to only bend it on pre-folded lines.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2 // round corners
			}),

		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-fish',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `We have talked a lot about the liners and how to use them. Now let's pay closer attention to what goes in them: the fish.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-filled-liners',
		layout: 's3-m1-filled-liners',
		subtype: 'images',
		text: {
			en: `Click on the liners that are NOT filled properly.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Liner filled with fish - tails towards center
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // Liner with fish, tails outward
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // Liner filled above level
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // Liner filled below level
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // Liner with gap near corner
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-trolley',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		images: ['s3-m1-trolley'],
		text: {
			en: `After packaging and before freezing the fish, store the frames on a trolley.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-max-time-trolley',
		text: {
			en: `How long is it recommended to keep frames on the trolley before freezing?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					en: `30 minutes`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `If the fish isn't wet, you can leave it for up to 60 minutes, but we don't recommend it.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					en: `15 minutes`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					en: `1 hour`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					en: `10 minutes`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					en: `45 minutes`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-liner-adhere',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `Please note that a bit of stand time is needed for the liner to adhere properly. Leave the frames in the trolley for at least 10 minutes.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-store-frames-stacked',
		text: {
			en: `Can you store the frames on top of each other?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					en: `No. The weight can cause drip loss and damage liners`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					en: `Yes, the frame is metal, so no problem`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					en: `Only inside the freezer`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					en: `Yes, but only for 10 minutes tops`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Nope. 6 layers on top leads to 120 kg's of pressure - that will easily damage the liner.`
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-plate-freezer',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `Okay, now we'll start placing blocks in the plate freezer.`
		},
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's3-m1-order-plate-freezer',
		layout: 's3-m1-plate-freezer',
		text: {
			en: `What is the correct order of operations when using the plate freezer?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					en: `Clean top and bottom with rubber scrubber`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					en: `Place frames in freezer`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					en: `Make sure to have full pressure`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					en: `Freeze to -33C`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's3-m1-spot-errors-filled-plate-freezer',
		layout: 's3-m1-filled-plate-freezer',
		background: 'production-2',
		text: {
			en: `Look at the image of a filled plate freezer. Click on the 4 issues. Click OK when you have them, or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'badly-packed-liner',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'misaligned-frame'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'different-height',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Yes! This comes from placing frames with different products in the same row. They have different heights.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'misaligned-frame2'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-block-rest-duration',
		text: {
			en: `After freezing, for how long should the blocks rest in frames before ejecting them?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					en: `7-10 minutes`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Exactly. This reduces the risk of damage to both block and frame.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					en: `3-5 minutes`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					en: `12 minutes`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					en: `20-30 minutes`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Far too long. You need to keep the cold chain intact to prevent partial thawing.`
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-pallets',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `When the blocks are frozen, store them on pallets.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-pallets',
		layout: 's3-m1-pallets',
		subtype: 'images',
		text: {
			en: `Which pallets have issues? Click on the problematic ones.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Perfect pallet with wrapping and cardboard top and bottom
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Wrapping, bottom, no top cover
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Yes, you need a top cover too.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // Top and bottom cover. No wrapping
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `Don't forget to wrap the entire pallet.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // Blocks are misaligned. Top and bottom + wrapping.
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager hairnet',
							text: {
								en: `This might happen if you don't put cardboard for every four layers`
							}
						})
					})
				] 
			}),

		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-training-done',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `That's it for today. By following our guidelines, you can ensure high quality for all your customers. `
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-finish',
		characters: ['manager hairnet head-1 armLeft-1 armRight-2'],
		text: {
			en: `If you have three stars, you have completed the training.`
		},
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's3-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};