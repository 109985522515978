const scenariosData = [
	{
		id: 'scenario-1',
		playerLoginType: 'employee-id',
		title: {
			da: 'Grundlæggende hygiejnetræning',
			en: 'Basic hygiene training',
		},
		loginTitle: {
			da: 'Hygiejnetræning',
			en: 'Hygiene training'
		},
		pointsAndStarsAreEnabled: true,
		disableAfter3Attempts: true,
		languageIds: ['da', 'en'],
		background: 'outside',
		modulesData: require('data/scenarios/scenario-1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-1/modules-data').moduleGroups,
	},
	{
		id: 'scenario-2',
		playerLoginType: 'name-email',
		title: {
			da: 'Hurtig hygiejnetræning',
			en: 'Quick hygiene training',
		},
		loginTitle: {
			da: 'Hygiejnetræning',
			en: 'Hygiene training'
		},
		pointsAndStarsAreEnabled: false,
		disableAfter3Attempts: false,
		languageIds: ['da', 'en'],
		background: 'outside',
		modulesData: require('data/scenarios/scenario-2/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-2/modules-data').moduleGroups,
	},
	{
		id: 'scenario-3',
		playerLoginType: 'name-email',
		title: {
			da: 'Liner Game',
			en: 'Liner Game'
		},
		loginTitle: {
			da: 'Liner Game',
			en: 'Liner Game'
		},
		pointsAndStarsAreEnabled: true,
		disableAfter3Attempts: false, // TODO
		languageIds: ['en'],
		background: 'outside-2',
		modulesData: require('data/scenarios/scenario-3/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-3/modules-data').moduleGroups,
	}

];

export {
	scenariosData
};