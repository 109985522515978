/* Instructions */
const instructionsTemplate = {
	mainTemplate: {
		type: 'instructions',
		isSolveToContinue: false,
		showProgressBar: true,
		animateCharacter: false,
		background: null,
		backgroundModifier: null,
		title: null,
		text: null,
		textType: 'speech', // 'info', 'speech',
		characters: ['manager head-1 armLeft-1 armRight-1'],
		images: []
	}
}

/* Image */
const imageTemplate = {
	mainTemplate: {
		type: 'image',
		isSolveToContinue: false,
		showProgressBar: true,
		image: null,
		text: null,
	}
}

/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		isSolveToContinue: true,
		isDilemma: false,
		subtype: null, // null, images
		showRequiredNumberOfAnswers: true, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: 1, // used if isDilemma
		maxPoints: 0, // used if isDilemma
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		showProgressBar: true,
		image: null,
		text: null,
		options: [],
		doneEffects: []
	},
	optionTemplate: {
		text: null, 
		isCorrect: false,
		effects: []
	}
};

/* Sort */
const sortTemplate = {
	mainTemplate: {
		type: 'sort',
		subtype: null, // null, images
		isSolveToContinue: true,
		showProgressBar: true,
		layout: '',
		text: null,
		image: null,
		categories: [],
		items: []	
	},
	categoryTemplate: {
		title: null,
		text: null,
	},
	itemTemplate: {
		text: null,
		categoryIds: []
	}
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		type: 'order',
		subtype: null, // null, images
		isSolveToContinue: true,
		showProgressBar: true,
		image: null,
		text: null,
		items: []
	},
	itemTemplate: {
		text: null,
		correctOrderNumbers: []
	}
};

/* Organize */
const organizeTemplate = {
	mainTemplate: {
		type: 'organize',
		isSolveToContinue: true,
		showProgressBar: true,
		showSolutionWhenDone: false,
		layout: '',
		title: null,
		text: null,
		items: [],
		rules: [],
		mainContainers: [],
		doneConditions: [],
		doneEffects: []
	},
	itemTemplate: {
		containerId: null, 
		image: null, 
		alt: null,
		correctContainerIds: []
	},
	mainContainerTemplate: {
		title: null,
		containers: []
	},
	containerTemplate: {
		type: '',
		finalItemId: null
	},
	doneConditionTemplate: {
		type: '', // empty-main-container
		mainContainerId: null
	},
	rulesTemplate: {
		type: '', // items-in-correct-containers
	}
};

/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		type: 'spot-errors',
		background: 'production',
		layout: '',
		isSolveToContinue: true,
		showProgressBar: true,
		doneWhenAllErrorsSpotted: false,
		showDefaultResultPopup: true,
		text: null,
		errors: [],
		doneEffects: []
	},
	errorTemplate: {
		effects: []
	}
};

/* Survey */
const surveyTemplate = {
	mainTemplate: {
		type: 'survey',
		isSolveToContinue: true,
		subtype: null, // null, images
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		minOptionsToSelect: 1,
		maxOptionsToSelect: 1,
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		showProgressBar: true,
		image: null,
		title: null,
		text: null,
		options: [],
		doneEffects: [],
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	},
	optionTemplate: {
		text: null, 
		effects: []
	}
};

export {
	multipleChoiceTemplate,
	instructionsTemplate,
	spotErrorsTemplate,
	organizeTemplate,
	imageTemplate,
	orderTemplate,
	sortTemplate,
	surveyTemplate
};
