const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-welcome',
		animateCharacter: true,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Velkommen til Becks grundlæggende hygiejnetræning. Du skal gennemgå denne træning før du må få adgang til produktionen.`,
			en: `Welcome to Beck's basic hygiene training. You have to complete this training before entering the production facilities.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-points-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Træningen er lavet som små opgaver, der gradvist lærer dig vores regler og procedurer.`,
			en: `The training consists of a series of small situations or tasks that teaches you about our rules and procedures.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Det tager kun fem minutter at gennemføre. God fornøjelse!`,
			en: `It will only take five minutes to complete. Have fun!`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-hair-and-beard',
		layout: 's2-m1-hair-and-beard',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Inden du går ind i produktionen, hvordan skal hårnet og skægbind sidde? Tryk på de rigtige billeder.`,
			en: `Before entering production, what is the correct way to use hair- and beardnets? Click the correct images.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // hair and ears covered by hair net, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // hair and ears covered by hair net, beard covered by beardnet
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis dit skæg er under 10mm, behøver du ikke have skægbind på.`,
								en: `If your beard is less than 3/8 inches, you don't need a beardnet.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // hair sticks out, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // hair and ears covered by hair net, beard but no beardnet
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // ears stick out, no beard
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Hvilket smykke er det eneste, du må have på i produktionen?`,
			en: `What kind of jewelry is the only one allowed in production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // smartwatch
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Bemærk dog at smartwatch ikke er tilladt på vores amerikanske fabrik.`,
								en: `Note that smartwatches are not allowed in our American location.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Næsepiercing, glat, fast lukke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Vielsesring uden sten
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Glat ørering med fast lukke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Vielsesring med sten
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Ørering, stift
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-lubricants-and-chemicals',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvad gælder for smøremidler, kemikalier og lignende, som du skal bruge?`,
			en: `What is true of the lubricants, chemicals, and so on that you use?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `De skal være godkendt til fødevarekontakt`,
					en: `They must be approved for contact with foodstuff`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `De skal være godkendt af Beck`, 
					en: `They must be approved by Beck`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `De skal være billige`,
					en: `They must be cheap`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `De skal være økologiske`,
					en: `They must be organic`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `De skal bæres i en klar plastikpose`,
					en: `I have to carry them in a clear plastic bag`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er vist kun i lufthavnen den regel gælder.`,
								en: `I think that rule only applies in the airport.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-ready-production',
		background: 'dressing-room',
		text: {
			da: `Nu gør vi klar til at gå ind i produktionen. Husk at lade dine personlige ejendele blive udenfor.`,
			en: `Now we are ready to enter the production facilities. Remember to leave your personal belongings outside.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-always-do-before-production',
		background: 'dressing-room',
		text: {
			da: `Hvad skal du altid huske at gøre, inden du går ind i produktionen?`,
			en: `What must you always remember to do before entering production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Vaske hænder`,
					en: `Wash my hands`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Give alle en high five`, 
					en: `High five everyone`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Tage hårnet af`,
					en: `Remove my hairnet`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-washing-hands-areas',
		layout: 's1-m1-hands',
		background: 'dressing-room',
		subtype: 'images',
		shuffleOptions: false,
		text: {
			da: `Der er især to områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Two areas are especially easy to forget when washing your hands. What are they?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // inside of wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // between fingers
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		text: {
			da: `Håndhygiejne handler også om neglene. 
				<br />Tryk på det billede, som er korrekt.`,
			en: `The nails are also an important part of hand hygiene.
				<br />Click on the correct image.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // short, clean
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // short, nail polish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // short, slightly dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // long, slightly dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // artificial nails
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-nails-gloves',
		background: 'dressing-room',
		text: {
			da: `Hvis du har kunstige negle eller neglelak på, så skal du bære engangshandsker. Og sikkerhedsbriller hvis du bruger kunstige øjenvipper.`,
			en: `If you are wearing artificial nails or nail polish, you have to wear single use gloves. And safety goggles if you are using artificial eyelashes.`
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-important-rule',
		background: 'dressing-room',
		text: {
			da: `Inden du går ind i produktionen, er der en vigtig regel.`,
			en: `Before entering production, we have an important rule.`
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-suspect-disease',
		background: 'dressing-room',
		text: {
			da: `Hvis du har mistanke om en fødevarebåren sygdom - det kan være influenza eller madforgiftning - så kontakt nærmeste arbejdsleder. Men det her er et spil, så du må gerne fortsætte.`,
			en: `If you suspect you've been infected with a foodborne illness - for instance stomach flu or food poisoning - contact your closest manager. But since this is a game, you are allowed to continue.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-visible-cuts',
		subtype: 'images',
		background: 'dressing-room',
		layout: 's1-m2-visible-cuts-cover',
		text: {
			da: `Hvis nu du har synlige sår eller rifter, hvad skal du så sætte på?`,
			en: `If you have visible wounds or scratches, what should you put on it?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Blåt plaster
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Vores blå plaster er detekterbart, så du må kun bruge udleverede plastre.`,
								en: `Our blue band aids are detectable. So you are only allowed to use our band aids.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Almindeligt plaster
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Kæmpe bandage 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Plastikpose hen over armen. 
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's2-m1-spot-errors-production-issues',
		layout: 's2-m1-production-issues',
		background: 'production',
		text: {
			da: `Kan du finde de tre problemer her? Klik på problemerne. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Can you spot the three issues here? Click on the issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tool'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tool2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hairnet'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-tools-not-wood',
		background: 'production',
		text: {
			da: `Al værktøj skal være uden træ. Det er som udgangspunkt ikke tilladt at anvende produktionens værktøj. Husk også at sikre området og at afdække, før du udfører arbejde.`,
			en: `No wooden tools are allowed. As a general rule, it is not allowed to use the production tools. Also, remember to secure the area before doing work.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-after-finishing-task',
		background: 'production',
		text: {
			da: `Hvad skal du gøre, når du er færdig med arbejdet ved et område eller en maskine?`,
			en: `What must you do when you are done working in an area or on a machine?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Støvsuge eller rengøre`,
					en: `Vacuum or clean`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Have frigivet område/maskine af Beck`, 
					en: `Have the area/machine released by Beck`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Drikke en kop kaffe`,
					en: `Have a cup of coffee`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du må kun drikke vand i produktionen.`,
								en: `You are only allowed to drink water in production`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Skrive en faktura`,
					en: `Write an invoice`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det kan du gøre, når du er helt færdig. Og ikke mens du er i produktionen.`,
								en: `Do that when you are done. And not while you are in production.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Genstarte maskinen`,
					en: `Restart the machine`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-clothes-when-working-with-product',
		background: 'production',
		text: {
			da: `Hvis du arbejder med produkter eller over produkter, så skal du desuden bære fx en BECK-kittel, en sweatshirt eller t-shirt. `,
			en: `If you work with or above product, you have to wear a BECK coat, sweatshirt, or T-shirt.`
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-clothes-beck-standard',
		background: 'production',
		text: {
			da: `Hvis dit tøj ikke er Becks standard, så skal du bruge tøj udleveret af Beck.`,
			en: `If your clothes don't adhere to Beck standards, you must use clothing given to you by Beck.`
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-last-task',
		background: 'production',
		text: {
			da: `Sidste opgave handler om, hvad du må og ikke må gøre.`,
			en: `The final task is about do's and dont's.`
		},
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 's2-m1-sort-allowed-or-not',
		background: 'production',
		text: {
			da: `Sorter følgende i hvad der er tilladt, hvad der ikke tilladt og hvad der er tilladt i særlige områder hos Beck`,
			en: `Sort the following into what is allowed, what is not allowed, and what is allowed in marked areas on Beck.`
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1,
				title: {
					da: `Tilladt`,
					en: `Allowed`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2,
				title: {
					da: `Ikke tilladt`,
					en: `Not allowed`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3,
				title: {
					da: `Tilladt i særlige områder`,
					en: `Allowed in marked areas`
				}
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, text: {da: `Rygning og snus`, en: `Smoking and snuff`}, categoryIds: [3]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, text: {da: `Drikke vand`, en: `Drinking water`}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, text: {da: `Drikke andet end vand`, en: `Drinking other things besides water`}, categoryIds: [3]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, text: {da: `Nøddeprodukter`, en: `Products containing nuts`}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, text: {da: `Hoste og nyse ved produkterne`, en: `Coughing and sneezing near product`}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, text: {da: `Mobiltelefon`, en: `Cellphones`}, categoryIds: [3],
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Husk dog, at den skal have påmonteret panserglas. Husk også, at du skal have en tilladelse fra ansvarlig leder.`,
								en: `Please remember that it must have a cover with tempered glass. And you will also need a permission from a manager.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, text: {da: `Tyggegummi`, en: `Chewing gum`}, categoryIds: [2]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-warm-work',
		background: 'production',
		text: {
			da: `Husk, hvis du skal lave "varmt arbejde", så skal du have udleveret en særlig instruks. Spørg en produktionsleder.`,
			en: `Remember, if you are doing 'hot work', you need special instructions. Ask a production manager.`
		},
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-finish',
		background: 'production',
		text: {
			da: `Sådan, du har nu været igennem træningen. Tak for din tid.`,
			en: `That's it! You've completed the training. Thank you for your time.`
		},
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's2-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};